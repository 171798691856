class BlackBannerCtaGloIT extends BATComponent{

	beforeLoad() {
		// 
	}

	afterLoad() {
	}

	beforeUpdate() {
		//
	}

	afterUpdate() {
	}

	unload() {
		//
	}

    
}

!customElements.get('bat-cta-gloitblackbanner') && customElements.define('bat-cta-gloitblackbanner', BlackBannerCtaGloIT);
